import React from "react";
import { Link } from "gatsby";
import FacebookBadge from "../../components/FacebookBadge";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { Helmet } from "react-helmet";
import Grid from "@material-ui/core/Grid";
import { faClock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./articles.css";

//Import At least four Images for the Article
import articleImage_1 from "../../images/services/Savente-Consulting-Limited-Services-01.jpg";
import articleImage_2 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-03.jpg";
import articleImage_3 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-01.jpg";
import articleImage_4 from "../../images/social-media-images/SL-120219-25650-11 [Converted]-04.jpg";

const articleDetails = {
  title: "Clinical Research Services in Nigeria",
  date: "8th June, 2021",
  datePublished: "2021-06-08T08:00:00+08:00", // Y-M-d
  dateModified: "2021-06-08T08:00:00+08:00", //
  author: "Savante Consulting Limited",
  supurb:
    "We've put together a comprehensive package of clinical research services to help you get the tools and expertise you need for your clinical research. These services are designed to meet the highest standards throughout your drug development strategy. Savante Consulting Limited is a Nigerian ...",
};

const images = [
  {
    image: articleImage_1,
    alt: "Clinical Research Services in Nigeria",
    key: 1,
  },
  {
    image: articleImage_2,
    alt: "Clinical Research Services in Nigeria",
    key: 2,
  },
  {
    image: articleImage_3,
    alt: "Clinical Research Services in Nigeria",
    key: 3,
  },
  {
    image: articleImage_4,
    alt: "Clinical Research Services in Nigeria",
    key: 4,
  },
];

const listItems = images.map((image) => (
  <div key={image.key} className="col s12 l3 hide-on-small-only">
    <img src={image.image} className="responsive-img" alt={image.alt} />
  </div>
));

const style = {
  listDot: {
    listStyleType: "disc",
  },
};

export default function Partnerships() {
  return (
    <Layout>
      <article>
        <SEO
          title={articleDetails.title}
          description={articleDetails.supurb}
          image={articleImage_2}
        />
        <Helmet>
          <script type="application/ld+json">
            {`                    
                      {
                        "@context": "https://schema.org",
                        "@type": "NewsArticle",
                        "headline": "` +
              articleDetails.title +
              `",
                        "image": [
                          "` +
              articleImage_1 +
              `",
                          "` +
              articleImage_2 +
              `",
                          "` +
              articleImage_3 +
              `",
                          "` +
              articleImage_4 +
              `",
                         ],
                        "datePublished": "` +
              articleDetails.datePublished +
              `",
                        "dateModified": "` +
              articleDetails.dateModified +
              `"
                      }
                  
                  
                `}
          </script>
        </Helmet>
        <br />
        <br />
        <div className="container article">
          <h1 className="title secondary-color-dark-text mb-5">
            {articleDetails.title}
          </h1>
          <Grid container>
            <Grid item xs={12}>
              <time className="pr-10">
                <FontAwesomeIcon
                  className="mr-8"
                  style={{ width: "1.125em" }}
                  aria-label="Mute volume"
                  icon={faClock}
                />
                <span>{articleDetails.date}</span>
              </time>
              <time>
                <FontAwesomeIcon
                  className="mr-8"
                  style={{ width: "1.125em" }}
                  aria-label="Mute volume"
                  icon={faUser}
                />
                <span>{articleDetails.author}</span>
              </time>
            </Grid>
          </Grid>
          <div className="row">
            <div className="col s12 l8 article-body">
              <Link
                to="/contact-us"
                className="btn btn-small waves-effect waves-light  getqouteButton hide-on-large-only  mb-10 mt-10"
                style={{ width: "100%" }}
              >
                Contact Us
              </Link>

              <div class="justify">
                <h5>
                  <strong>
                    <span data-preserver-spaces="true">
                      Clinical Research Services for Your Help
                    </span>
                  </strong>
                </h5>
                <p>
                  <span data-preserver-spaces="true">
                    We've put together a comprehensive package of clinical
                    research services to help you get the tools and expertise
                    you need for your clinical research. These services are
                    designed to meet the highest standards throughout your drug
                    development strategy. Savante Consulting Limited is a
                    Nigerian company that conducts contract clinical trials for
                    clients in the pharmaceutical, medical devices,
                    biotechnology industries and government and non-profit
                    organizations.
                  </span>
                </p>

                <h5>
                  <strong>
                    <span data-preserver-spaces="true">
                      Clinical Trials in Nigeria
                    </span>
                  </strong>
                </h5>
                <p>
                  <span data-preserver-spaces="true">
                    Clinical research is at the heart of a multibillion-dollar
                    industry centered on the human population's health. It has
                    been a focal point of industrial policy in countries such as
                    China. With the rapid expansion of advanced therapy
                    medicinal products. Medicine is undergoing a revolution—from
                    gene therapy and regenerative medicine to more precise
                    treatments and spectacular breakthroughs in devices, few
                    periods in history have seen as much innovation and
                    progress. These discoveries necessitate the participation
                    and engagement of patients all over the world, but this
                    requires a significant research infrastructure, which
                    includes regulatory authorities, industry, associations,
                    well-trained experts, a robust health system, and other
                    factors. Nigeria has been establishing a clinical trial
                    business with little fanfare throughout the world. According
                    to one source, clinical trials could one day create more
                    resources for Nigeria than crude oil—Clinical research can
                    become one of the prominent national sources of income for
                    the country.
                  </span>
                </p>

                <h5>
                  <strong>
                    <span data-preserver-spaces="true">
                      Most Common Types of Clinical Trials
                    </span>
                  </strong>
                </h5>
                <p>
                  <span data-preserver-spaces="true">
                    Most Common Types of Clinical Trials Offered by Savante
                    Consulting
                  </span>
                </p>
                <table style={{ borderstyle: "solid", float: "left" }}>
                  <tbody>
                    <tr style={{ borderBottom: "none" }}>
                      <td>
                        <ul>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              MAMS trials
                            </span>
                          </li>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              Screening &amp; Treatment Trials
                            </span>
                          </li>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              Prevention Trials
                            </span>
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              Feasibility Studies
                            </span>
                          </li>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              Cross-sectional studies
                            </span>
                          </li>
                          <li
                            style={{ fontweight: "400", listStyleType: "disc" }}
                            aria-level="1"
                          >
                            <span
                              style={{
                                fontweight: "400",
                                listStyleType: "disc",
                              }}
                            >
                              Cohort Studies
                            </span>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <br />

                <h5>
                  <strong>
                    <span data-preserver-spaces="true">
                      Progress in Regulations and Ethics of Clinical Research
                    </span>
                  </strong>
                </h5>
                <p>
                  <span data-preserver-spaces="true">
                    Nigeria has increased its efforts to improve product
                    development and marketing authorization regulations. In
                    Nigeria, for example, Savante Consulting Limited has written
                    about most of the country's regulation on clinical trials,
                    product importation, and marketing license requirements.
                    Savante Consulting has also vetted and registered several
                    medical products acted as independent consultants for
                    0rganizations. In Nigeria, ethical committee approval for
                    clinical studies can currently be obtained through online
                    applications. Other beneficial clinical trial projects in
                    Nigeria have proven successful, and sponsors would be
                    pleased.
                  </span>
                </p>

                <h5>
                  <strong>
                    <span data-preserver-spaces="true">Our Goal</span>
                  </strong>
                </h5>
                <p>
                  <span data-preserver-spaces="true">
                    The goal of Savante Consulting is to identify perceived
                    shortages in clinical research knowledge. We also want to
                    increase abilities across diverse clinical research
                    positions in Nigerian health institutions and contribute to
                    clinical research capacity improvement.
                  </span>
                </p>
              </div>
            </div>
            <div className="col s12 l4 p-0 center">
              <Link
                to="/contact-us"
                className="btn btn-small waves-effect waves-light hide-on-small-only contact-us-btn"
                style={{ width: "100%" }}
              >
                Contact Us
              </Link>
              <br />
              <br />
              <FacebookBadge src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsavanteconsultingofficial%2F&amp;tabs=timeline&amp;width=340&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId" />
              <br />
              <iframe
                title="Google Badge"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.5355607532397!2d3.387330214770342!3d6.45361289533113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8b568f3f5fc1%3A0xb2c59461ac52d3b7!2sSavante%20Consulting%20Limited!5e0!3m2!1sen!2sru!4v1615677592874!5m2!1sen!2sru"
                allowFullScreen=""
                width="340"
                height="300"
                frameBorder="0"
              ></iframe>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}
